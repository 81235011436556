<template>
  <span class="base-separator"/>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.base-separator {
  width: 100%;
  height: 1px;
  background-color: var(--gray-monochrome-40);
}
</style>
